import React, { useEffect } from 'react';
import { SnackbarProvider } from 'notistack';
import { MuiThemeProvider } from '@material-ui/core';
import { Switch, BrowserRouter as Router } from 'react-router-dom';

import ErrorBoundary from './shared/ErrorBoundary';
import NotFound from './shared/NotFound';
import '../scss/app.scss';
import {
  PublicRoute,
  AuthenticatedRoute,
  UnAuthenticatedRoute,
  DashboardRoute,
} from './Route';
import {
  authenticatedRoutes,
  unAuthenticatedRoutes,
  dashboardRoutes,
} from './routes';
import { muiTheme } from './Theme';
import AppProvider from './context/AppProvider';
import CreditsProvider from './context/CreditsProvider';
import useResponsive from './hooks/useResponsive';
import { internalUrl, rootUrls } from './Urls';
import NotAvailableForMobile from './NotAvailableForMobile';
import SocketProvider from './context/SocketProvider';
import IframeProvider from './context/IframeProvider';
import { useAppContext } from './context/AppContext';

import { TrackEvent } from './utils/analytics';
import { SELLER_WEB_VIEWED } from './events';
import SubscriptionProvider from './context/SubscriptionProvider';
import CountryProvider from './context/CountryProvider';
import InvoiceProvider from './context/InvoiceProvider';
import PaymentsProvider from './context/PaymentsProvider';
import Token from './Token';
import { useQueryWithSearch } from './hooks/useQuery';
import ThemeProvider from './context/ThemeProvider';
import DeliveryProvider from './context/DeliveryProvider';
import StoreProfileProvider from './context/StoreProfileProvider';
import CanvaProvider from './context/CanvaProvider';
import OneTapLogin from './Token/OneTapLogin';
import StorePreferenceProvider from './context/StorePreferenceProvider';
import ForwardingEmailProvider from './context/ForwardingEmailProvider';
import UnsavedChangesModalProvider from './context/UnsavedChangesProvider';
import SizeChartProvider from './context/SizeChartProvider';
import {
  DefaultSnackbarIcon,
  ErrorSnackbarIcon,
  SuccessSnackbarIcon,
} from './SvgIcon';
import AnalyticsProvider from './context/AnalyticsProvider';
import PluginsProvider from './context/PluginsProvider';
import { LINK_MINK_KEY } from './constants';
import OrderOptionsProvider from './context/OrderOptionsProvider';
import OrdersProvider from './context/OrdersProvider';
import StoreWarehouseProvider from './context/StoreWarehouseProvider';
import ViewReportsProvider from './context/ViewReportsProvider';
import dukaanFavicon from '../images/favicon.png';
import defaultFavicon from '../images/default-favicon.svg';
import { WHITELISTED_ADDRESSES } from './InternationalLogin/constants';
import SendStoreID from './Token/SendStoreID';
import useStorageListenReload from './hooks/useStorageListenReload';
import ViewNotificationsProvider from './context/ViewNotificationsProvider';
import { initializeSentry } from '../sentry';

global.React = React;

if (process.env.NODE_ENV !== 'development') {
  initializeSentry();
}

const App = () => {
  const { isMobile } = useResponsive();
  const { business } = useAppContext();
  const query = useQueryWithSearch(window.location.search);
  const redirectUrl = query.get('redirectUrl');

  useStorageListenReload();

  useEffect(() => {
    if (typeof LinkMink === 'function') {
      LinkMink(LINK_MINK_KEY).initTracking().then();
    }
    TrackEvent(SELLER_WEB_VIEWED, business);

    const isWhitelistedAddress = WHITELISTED_ADDRESSES.some((address) =>
      (window.location.hostname ?? '').includes(address)
    );
    const setFavicon = (faviconIcon) => {
      let faviconLink = document.querySelector("link[rel~='icon']");
      if (!faviconLink) {
        faviconLink = document.createElement('link');
        faviconLink.rel = 'icon';
        faviconLink.href = faviconIcon;
        document.getElementsByTagName('head')[0].appendChild(faviconLink);
      }
    };
    if (isWhitelistedAddress) {
      setFavicon(dukaanFavicon);
      document.title = 'Login | Dukaan';
    } else {
      setFavicon(defaultFavicon);
      document.title = 'Login';
    }
  }, []);

  return (
    <ErrorBoundary>
      <MuiThemeProvider theme={muiTheme}>
        <AppProvider>
          <ViewNotificationsProvider>
            <ViewReportsProvider>
              <CreditsProvider>
                <CountryProvider>
                  <InvoiceProvider>
                    <PaymentsProvider>
                      <CanvaProvider>
                        <SnackbarProvider
                          iconVariant={{
                            success: <SuccessSnackbarIcon />,
                            error: <ErrorSnackbarIcon />,
                            default: <DefaultSnackbarIcon />,
                          }}
                          maxSnack={2}
                          classes={{
                            root: 'snackbar',
                            contentRoot: 'snackbar-content',
                            message: 'snackbar-message',
                            action: 'snackbar-action',
                            variantSuccess: 'snackbar-success',
                            variantError: 'snackbar-error',
                            containerRoot: 'min-width-100 snackbar-container',
                          }}
                          anchorOrigin={{
                            horizontal: 'center',
                            vertical: isMobile ? 'bottom' : 'top',
                          }}
                        >
                          <SubscriptionProvider>
                            <OrderOptionsProvider>
                              <SocketProvider>
                                <IframeProvider>
                                  <StoreWarehouseProvider>
                                    <DeliveryProvider>
                                      <ThemeProvider>
                                        <StoreProfileProvider>
                                          <StorePreferenceProvider>
                                            <AnalyticsProvider>
                                              <OrdersProvider>
                                                <SizeChartProvider>
                                                  <PluginsProvider>
                                                    <ForwardingEmailProvider>
                                                      <Router>
                                                        <UnsavedChangesModalProvider>
                                                          <Switch>
                                                            {/* <PublicRoute
                                                            path={
                                                              rootUrls.notAvailablePath
                                                            }
                                                            exact
                                                            component={
                                                              NotAvailableForMobile
                                                            }
                                                            isMobile={isMobile}
                                                          /> */}
                                                            {dashboardRoutes.map(
                                                              (route) => (
                                                                <DashboardRoute
                                                                  key={
                                                                    route.path
                                                                  }
                                                                  {...route}
                                                                  isMobile={
                                                                    isMobile
                                                                  }
                                                                />
                                                              )
                                                            )}
                                                            {authenticatedRoutes.map(
                                                              (route) => (
                                                                <AuthenticatedRoute
                                                                  key={
                                                                    route.path
                                                                  }
                                                                  {...route}
                                                                  isMobile={
                                                                    isMobile
                                                                  }
                                                                />
                                                              )
                                                            )}
                                                            {unAuthenticatedRoutes.map(
                                                              (route) => (
                                                                <UnAuthenticatedRoute
                                                                  key={
                                                                    route.path
                                                                  }
                                                                  {...route}
                                                                  isMobile={
                                                                    isMobile
                                                                  }
                                                                  redirectUrl={
                                                                    redirectUrl
                                                                  }
                                                                />
                                                              )
                                                            )}
                                                            <PublicRoute
                                                              path={
                                                                internalUrl.setTokenUrl
                                                              }
                                                              component={Token}
                                                            />
                                                            <PublicRoute
                                                              path={
                                                                internalUrl.sendStoreIDUrl
                                                              }
                                                              component={
                                                                SendStoreID
                                                              }
                                                            />
                                                            <PublicRoute
                                                              path={
                                                                internalUrl.onetapLoginUrl
                                                              }
                                                              component={
                                                                OneTapLogin
                                                              }
                                                            />
                                                            <PublicRoute
                                                              path="/404"
                                                              component={
                                                                NotFound
                                                              }
                                                              isMobile={
                                                                isMobile
                                                              }
                                                            />
                                                            <PublicRoute
                                                              path="*"
                                                              component={
                                                                NotFound
                                                              }
                                                              isMobile={
                                                                isMobile
                                                              }
                                                            />
                                                          </Switch>
                                                        </UnsavedChangesModalProvider>
                                                      </Router>
                                                    </ForwardingEmailProvider>
                                                  </PluginsProvider>
                                                </SizeChartProvider>
                                              </OrdersProvider>
                                            </AnalyticsProvider>
                                          </StorePreferenceProvider>
                                        </StoreProfileProvider>
                                      </ThemeProvider>
                                    </DeliveryProvider>
                                  </StoreWarehouseProvider>
                                </IframeProvider>
                              </SocketProvider>
                            </OrderOptionsProvider>
                          </SubscriptionProvider>
                        </SnackbarProvider>
                      </CanvaProvider>
                    </PaymentsProvider>
                  </InvoiceProvider>
                </CountryProvider>
              </CreditsProvider>
            </ViewReportsProvider>
          </ViewNotificationsProvider>
        </AppProvider>
      </MuiThemeProvider>
    </ErrorBoundary>
  );
};

export default App;
