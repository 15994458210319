import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { Form } from 'react-final-form';
import { useCountry } from '../../context/CountryProvider';
import { useCredits } from '../../context/CreditsProvider';
import useCustomSnackbar from '../../hooks/useCustomSnackbar';
import useTimeoutLoader from '../../hooks/useTimeoutLoader';
import FormNumberInput from '../../shared/Form/FormNumberInput';
import Modal from '../../shared/Modal';
import SpinnerButton from '../../shared/SpinnerButton';
import { CrossThinIcon } from '../../SvgIcon';
import { noop } from '../../utils';
import { formatNumber, numberWithCommas, roundTo } from '../../utils/number';
import { MIN_CREDITS_TO_ADD } from '../constants';
import useResponsive from '../../hooks/useResponsive';
import useEnterPressAction from '../../hooks/useEnterPressAction';
import { SuccessBadge } from '../../shared/Badge';
import { DUKAAN_SERVICE_FEE_URL } from '../../constants';
import {
  NUDGE_ICON_VARIANTS,
  NUDGE_SIZES,
  NUDGE_TYPES,
  NUDGE_VARIANTS,
} from '../../Subscription/constants';
import Nudge from '../../Subscription/components/Nudge';

const CreditsErrorMessage = ({ title, message = '' }) => (
  <div className="information-banner-red mb24">
    {title && <p className="c-red-1 section-text-5">{title}</p>}
    {message && <p>{message}</p>}
  </div>
);

// const CREDIT_OFFER_SLOTS = [[2500, 1250]];

const findApplicableDiscount = (creditsValue) =>
  creditsValue >= 2500 ? creditsValue * 0.5 : 0;

// Initialize with the default discount of 0

// for (const [creditThreshold, discount] of CREDIT_OFFER_SLOTS) {
//   if (creditsValue >= creditThreshold) {
//     applicableDiscount = discount; // Update the discount if the threshold is met
//   }
// }

const AddCreditsModal = ({
  open,
  initialValue = MIN_CREDITS_TO_ADD,
  errorMessage: defaultErrorMessage,
  closeModal,
  callback = undefined,
  showMinimum = true,
  title = 'How many credits do you want to purchase?',
  maxWidth = 'sm',
  className = '',
}) => {
  const { isMobile } = useResponsive();
  const { formatLocalMoney } = useCountry();
  const { creditsToAdd, setCreditsToAdd, generatePaymentOrder } = useCredits();
  const [errorMessage, setErrorMessage] = useState();
  const { enqueueSnackbar } = useCustomSnackbar();
  const { loading, setLoadingTimeOut } = useTimeoutLoader(5000);
  const [hideIcon, setHideIcon] = useState(true);

  const addValue = (additional) =>
    setCreditsToAdd(additional + (creditsToAdd ? Number(creditsToAdd) : 0));

  const handleSubmit = () => {
    if (creditsToAdd < initialValue) {
      setErrorMessage({
        title: `You need to add at least ${MIN_CREDITS_TO_ADD} credits`,
      });
    } else {
      setLoadingTimeOut();
      generatePaymentOrder(creditsToAdd, callback);
      enqueueSnackbar('Payment dialog will open shortly!', 'info', {
        action: null,
      });
    }
  };

  useEffect(() => {
    setCreditsToAdd(initialValue < 1 ? 1 : initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (defaultErrorMessage) {
      setErrorMessage(defaultErrorMessage);
    }
  }, [defaultErrorMessage]);

  useEnterPressAction(open, handleSubmit);

  return (
    <Modal
      open={open}
      closeModal={closeModal}
      paperClass={cx({ 'mobile-modal': isMobile })}
      maxWidth={maxWidth}
    >
      <Form onSubmit={noop} initialValues={{ amount: creditsToAdd }}>
        {({ values }) => (
          <div className={className}>
            <h2 className="section-text-4 mb12">Add credits</h2>
            {/* <Nudge
              variant={NUDGE_VARIANTS.INFO}
              type={NUDGE_TYPES.SECONDARY}
              size={NUDGE_SIZES.SMALL}
              text="Get FREE credits when you add more than 2500 credits"
              className="mb24"
              iconVariant={NUDGE_ICON_VARIANTS.BLUE}
            /> */}

            {errorMessage && <CreditsErrorMessage {...errorMessage} />}
            <p className="mb8">
              {title}{' '}
              {showMinimum && (
                <em>
                  (Minimum {formatNumber(initialValue) || MIN_CREDITS_TO_ADD})
                </em>
              )}
            </p>
            <FormNumberInput
              name="amount"
              required
              showRequired
              value={creditsToAdd}
              appendText={
                <CrossThinIcon
                  className={cx('cur-p', {
                    'd-none': hideIcon,
                  })}
                  onClick={() => setCreditsToAdd(initialValue)}
                />
              }
              onChange={(e) => {
                setCreditsToAdd(e.target.value);
                setErrorMessage(null);
                setHideIcon(parseInt(e.target.value) === initialValue);
              }}
            />
            <div className="d-flex align-center mb12">
              <div onClick={() => addValue(500)} className="btn-chip mr12">
                + 500
              </div>

              <div onClick={() => addValue(2000)} className="btn-chip mr12">
                + 2000
              </div>

              <div onClick={() => addValue(10000)} className="btn-chip mr12">
                + 10000
              </div>

              <div onClick={() => addValue(25000)} className="btn-chip mr12">
                + 25000
              </div>

              <div onClick={() => addValue(50000)} className="btn-chip mr12">
                + 50000
              </div>
            </div>
            <p className="my16 text-3 c-black-3">
              Dukaan credits are non-refundable.{' '}
              <a
                rel="noreferrer"
                target="_blank"
                href={DUKAAN_SERVICE_FEE_URL}
                className="anchor-6 underline text-medium"
              >
                Learn more
              </a>
            </p>
            {creditsToAdd > 0 && (
              <div>
                <p className="c-black-3 text-medium">
                  Total Amount to pay:{' '}
                  {formatLocalMoney(Number(roundTo(creditsToAdd ?? 1, 2) ?? 1))}
                </p>
                <p className="c-black-3 text-medium">
                  You get:{' '}
                  {numberWithCommas(Number(roundTo(creditsToAdd ?? 1, 2) ?? 1))}{' '}
                  Credits
                  {/* {findApplicableDiscount(creditsToAdd) > 0 ? (
                    <span>
                      {' '}
                      + {numberWithCommas(
                        findApplicableDiscount(creditsToAdd)
                      )}{' '}
                      Credits <SuccessBadge text="Free" />
                    </span>
                  ) : null} */}
                </p>
              </div>
            )}
            <div className="text-center mt24">
              <SpinnerButton
                type="button"
                isLoading={loading}
                className="btn-primary-4"
                disabled={creditsToAdd < initialValue}
                onClick={handleSubmit}
              >
                Pay{' '}
                {formatLocalMoney(Number(roundTo(creditsToAdd ?? 1, 2) ?? 1))}
              </SpinnerButton>
            </div>
          </div>
        )}
      </Form>
    </Modal>
  );
};
export default AddCreditsModal;
